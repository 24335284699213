/* J ADMIN LAYOUT */

.admin-layout {
  min-height: 100% !important;
  .left-menu {
    height: 100%;
    border-right: 0;
    padding-top: 94px;
    & > * {
      user-select: none;
    }
  }
  .ant-layout-sider-children {
    // height: calc(100% - 48px);
    height: 100%;
    position: fixed;
    left: 0;
    /* height: auto; */
    width: inherit;
    overflow-y: scroll;
  }
  .ant-menu {
    // font-family: "Bodoni 72", serif !important;
    font-size: 14px !important;
    &-inline-collapsed {
      .ant-menu-item,
      .ant-menu-submenu-title {
        padding-left: 24px !important;
      }
    }
    .ant-menu-submenu-open {
      margin-bottom: 10px;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      height: 30px !important;
      line-height: 30px !important;
    }
  }
  .main-title {
    // margin: 30px 24px;
    margin: 25px 0px;
    padding: 5px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;

    button {
      padding: 5px;
      height: 32px;
      width: 32px;
      margin-right: 5px;
      border-radius: 100%;
    }
  }
  .main-layout {
    margin-top: 64px;
    padding: 0 20px 80px;
    position: relative;
    // overflow: visible !important;
    .main-cont {
      padding: 0 24px 24px;
      border-radius: 10px;
      // box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      //   0 6px 16px 0 rgba(0, 0, 0, 0.08);
    }
  }
  // .ant-layout-sider-trigger {
  //   background: transparent;
  //   color: rgba(0, 0, 0, 0.5);
  //   position: absolute;
  //   right: -25px;
  //   bottom: 5px;
  //   height: auto !important;
  //   width: 34px !important;
  //   .anticon {
  //     background: #fff;
  //     border-radius: 100%;
  //     box-shadow: 2px 1px 10px -6px #000;
  //     padding: 10px;
  //     transition: right 200ms ease-in-out;
  //   }
  //   &:hover {
  //     right: -30px;
  //   }
  // }
  .dot-party {
    pointer-events: painted;
    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      width: 80px;
      height: 80px;
      background-repeat: no-repeat;
    }
    &.dot {
      &-purple {
        &::before {
          background-image: url(../../assets/images/dot-purple.svg);
        }
      }
      &-green {
        &::before {
          background-image: url(../../assets/images/dot-green.svg);
        }
        &-small {
          &::before {
            background-image: url(../../assets/images/dot-green-small.svg);
          }
        }
      }
      &-top {
        &::before {
          top: 20px;
        }
      }
      &-left {
        &::before {
          left: 20px;
        }
      }
      &-right {
        &::before {
          right: 20px;
        }
      }
      &-bottom {
        &::before {
          bottom: 20px;
        }
      }
      &-fill {
        &::before {
          z-index: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
        }
        &.dot-left {
          &::before {
            left: 0;
          }
        }
        &.dot-right {
          &::before {
            right: 0;
          }
        }
      }
    }
  }
  .version {
    text-align: center;
    color: rgba(0, 0, 0, 0.1);
    height: 0px;
    overflow: visible;
  }
}
