.profileLayout {
  min-height: 100% !important;
  &__mobileMenuButton {
    position: absolute !important;
    top: 10px;
    right: 10px;
  }
  &__header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: height 250ms ease-in-out, top 250ms ease-in-out;
    &.ant-layout-header {
      padding: 0;
      .ant-menu {
        line-height: 50px;
      }
    }
    height: max-content !important;
    // background: #fff !important;
    &-bar {
      padding-top: 10px;
      padding-right: 16px;
      line-height: 30px;
      a {
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }
    &-content {
      transition: 0.2s;
      padding: 10px 15px;
      //@media (max-width: 575px) {
      //  padding: 75px 16px 35px;
      //}
    }
    &-logo {
      cursor: pointer;
      max-width: 100px;
    }
    &-search {
      margin-left: 30px;
      width: 100%;
      max-width: 400px;
      @media (max-width: 575px) {
        margin-left: 0;
        margin-top: 20px;
      }
      &--after {
        background: var(--primary);
        color: #fff !important;
        width: 120px;
      }
      .ant-select-focused {
        .ant-select-selection-item {
          color: #fff !important;
        }
      }
      .ant-select-arrow {
        color: #fff;
      }
    }
  }
  &__menu {
    //background-color: var(--primary);
    background-color: #c7ceff;
    box-shadow: 0 5px 5px 2px rgba(0, 0, 0, 0.1);
    .ant-menu-horizontal {
      border-bottom: none !important;
      background: transparent;
    }
    .ant-menu-item {
      color: #1a53cb !important;
      border-bottom: none !important;
      &:hover {
        color: rgba(#1a53cb, 0.5) !important;
      }
      a {
        color: #1a53cb !important;
        &:hover {
          color: rgba(#1a53cb, 0.5) !important;
        }
      }
      &-selected {
        font-weight: 700;
      }
    }
  }

  &__content {
    padding: 0 16px 30px;
    // background-color: var(--grey-lighter);
    background-color: transparent;
    min-height: 100vh;
    width: 100%;
  }

  &__footer {
    background: var(--primary) !important;
    color: var(--light-grey) !important;
  }
}
