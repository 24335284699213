@import "../../assets/styles/colors";

.profilePage {
  & .title {
    &:before {
      display: none;
    }
    color: var(--primary);
    font-weight: bold;
  }
}
