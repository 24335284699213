@import "../../assets/styles/colors";

.customCard {
  .anticon {
    margin-right: 10px;
    font-size: 1rem;
  }
  p {
    display: flex;
    align-content: center;
  }
  &__crea {
    width: 100%;
    .ant-card-body {
      width: 100%;
    }
    &-title {
      color: $secondaryColor;
    }
    &-playCentered {
      color: #fff !important;
      cursor: pointer;
      height: 14px;
      margin: 0 auto;
      position: absolute;
      top: calc(50% - 7px);
    }
    &-image,
    &-placeholder {
      margin: 0 auto;
      width: 100%;
      min-width: 300px;
      height: 170px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &--play {
        font-size: 3rem;
        box-shadow: 20px 40px 80px 0 rgba(29, 29, 31, 0.08);
        cursor: pointer;
      }
      &--detail {
        height: 190px !important;
        min-width: auto;
        margin: 5px !important;
      }
    }
    &-image {
      &--play {
        color: #fff !important;
      }
    }
    &-placeholder {
      margin: 0 20px;
      //border: 1px solid #ccc;
      &--play {
        color: #ccc !important;
      }
    }
    &-slider {
      position: relative;
      max-width: 800px;
      text-align: center;
      margin: 0 auto;
      .slick-slider {
        display: flex;
        align-content: center;
        .slick-list {
          width: 100%;
        }
      }
      .slick-prev:before,
      .slick-next:before {
        display: none !important;
      }
      .arrow {
        display: flex;
        align-self: center;
        margin-top: -35px;
        font-size: 25px;
      }
    }
  }
  &__pitchList {
    &-title {
      color: $secondaryColor;
      margin-left: 5px;
    }
    &-title-no-margin {
      color: $secondaryColor;
      margin-left: 0;
    }
    .ant-list-item-meta {
      padding-top: 8px;
    }
    .ant-collapse-header {
      padding: 5px 0 0 !important;
      p {
        margin-bottom: 0 !important;
      }
    }
    .ant-list-item-meta-avatar {
      align-self: center;
    }
  }
}

.card {
  &__tagLinks {
    color: $secondaryColor !important;
    border-color: $secondaryColor !important;
  }
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: $secondaryColor !important;
  border-color: $secondaryColor !important;
}

.personalComment {
  .ant-collapse-header {
    background-color: $greyLightColor;
    color: $primaryColor !important;
    padding: 12px 24px 0px 24px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .ant-collapse-content {
    background-color: $greyLightColor !important;
    .ant-collapse-content-box {
      padding: 12px 24px !important;
    }
  }
  .person-commented {
    color: $primaryColor;
  }
  .date-commented {
    font-style: italic;
  }
  .icons {
    color: $primaryColor;
    font-size: 12px;
  }
}
