@import "../../assets/styles/colors";

.tagPage {
  &__tabs {
    .ant-tabs-tab {
      text-transform: uppercase;
      color: $secondaryColor !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $primaryColor !important;
      font-weight: bold !important;
    }
  }
}
