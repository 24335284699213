@import "../../assets/styles/colors";

.companyPage {
  position: relative;
  .ant-card {
    border-radius: 10px;
  }
  &__head {
    &-logo {
      max-width: 250px;
      max-height: 110px;
      height: auto;
      background: #fff;
      padding: 5px;
      text-align: center;
      position: relative;
    }
    .profile {
      &__container {
        position: relative;
        width: 100%;
        color: var(--light-grey);
        padding: 32px 16px;

        &--right {
          height: 160px;
          a {
            padding: 5px;
          }
          .anticon {
            color: #fff;
            font-size: 2rem;
            cursor: pointer;
          }
        }
        &--left {
          height: 160px;
        }
      }
      &__data {
        position: relative;
        margin-left: 20px;
        color: #fff;
        font-size: 1rem;
        h2 {
          color: #fff;
        }
      }
    }
  }
  &__body {
    margin-top: -130px;
    h2 {
      color: var(--secondary) !important;
    }
    &-card {
      background-color: $greyLightColor !important;
      margin-bottom: 20px !important;
      //box-shadow: -5px 3px 3px 1px rgba(0, 0, 0, 0.1);
      box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
      //box-shadow: 20px 40px 80px 0 rgba(29, 29, 31, 0.08);

      &--custom {
        .ant-card-head-title {
          padding: 10px 0 0;
        }
        .ant-card-head {
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }
      }
    }
    .detail-pane {
      background: #fff;
      border-radius: 10px;
      box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    }
  }
  .investments {
    &__tab {
      .ant-tabs-tab {
        text-transform: uppercase;
        color: var(--secondary);
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--secondary) !important;
        font-weight: bold !important;
      }
    }
  }
  .help-btn {
    color: $greyLightColor;
    font-weight: bold;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: $greyLightColor;
    }
  }
  .search-icn {
    color: #fff;
    font-size: 1rem !important;
    cursor: pointer;
  }
  .pitch_search,
  .brand_search {
    .anticon {
      font-size: 1rem;
    }
  }

  .company-group-link {
    color: #5257ff;
    cursor: pointer;
  }

  .company-parent-group-link {
    color: $orangeColor !important;
    cursor: pointer;
  }

  .arcemedia-logo {
    cursor: pointer;
    max-width: 100px;
  }
}
