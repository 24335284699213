.system-error {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  padding-top: calc(50vh - 200px);
  padding-left: calc(50vw - 250px);
  overflow-y: auto;
  @media (max-width: 767px) {
    padding: 10px;
  }
  .system-page {
    background-color: #fff;
    width: 500px;
    height: 300px;
    max-height: 80vh;
    max-width: 100vw;
    padding: 30px;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 20px 40px 80px 0 rgba(29, 29, 31, 0.08);

    @media (max-width: 767px) {
      width: calc(100vw - 20px);
      height: calc(100vh - 20px);
      max-height: calc(100vh - 20px);
      margin-left: 0;
    }
    .system-box {
      overflow: auto;
    }
  }
  &::before {
    content: "";
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.system-page {
  width: 100vw;
  height: 100vh;
  display: table;
  text-align: center;
  background-color: var(--grey);

  .system-box {
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
    width: 300px;
    .logo {
      margin: 0 auto 20px auto;
      svg {
        height: 50px;
        fill: black;
      }
    }
    .message-error {
      // font-family: var(--text-primary-font);
      // font-size: 1.3em;
      // margin: 15px 0;
      // font-weight: lighter;
      font-family: var(--text-primary-font);
      font-size: 1.2em;
      margin: 15px 0;
      font-weight: normal;
      color: #777;
      text-align: justify;
    }
    .code-error {
      margin: 15px 0;
      font-size: 1em;
      color: var(--btn-dark-text);
      font-family: monospace;
    }
    .message-head {
      font-size: 1.5em;
      font-family: var(--text-primary-font);
      margin: 30px 0;
    }
    .message {
      margin: 20px auto;
      max-width: 500px;
      &.error-box {
        font-size: 0.8em;
        background-color: rgb(31, 66, 95);
        padding: 12px 20px;
        border-radius: 4px;
        text-align: left;
        @media (max-width: 767px) {
          width: 300px;
          overflow-wrap: anywhere;
        }
        .error-message {
          color: rgb(148, 192, 156);
          font-family: monospace;
        }
      }
    }
    .action {
      width: 100%;
      margin: 20px auto 0 auto;
      button {
        width: 180px;
        margin: 0 10px;
      }
    }
  }
}
