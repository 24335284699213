.table-component {
  &.clickable-rows {
    .ant-table-row {
      cursor: pointer;
    }
  }
  .ant-table-cell {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  // td {
  // max-width: 30px !important;
  // }
  td,
  th {
    white-space: nowrap;
  }

  .empty-img {
    width: 130px;
    height: 80px;
  }
}
