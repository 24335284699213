@import "colors";

.muted-link {
  color: inherit;
  text-decoration: underline;
}
.ant-btn {
  &.ant-btn-primary {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: $greyLightColor;
    &:hover,
    &:active,
    &:focus {
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: $orangeColor;
      opacity: 0.8;
    }
  }
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
}
.ant-table-tbody {
  background-color: $greyLightColor;
}
.ant-table-cell {
  background-color: $greyLightColor !important;
}
thead[class*="ant-table-thead"] th {
  background-color: $greyLightColor !important;
}
// .ant-collapse-item {
//   background-color: $greyLightColor;
// }
