.brandPage {
  position: relative;
  &__modal {
    .ant-modal-header {
      padding: 0 20px;
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close-x {
      color: #fff;
    }
  }
  &__head {
    & .banner-top {
      min-height: unset;
    }
    &-logo {
      width: 100%;
      height: 100%;
      max-width: 205px;
      height: 80px;
      padding: 5px;
      text-align: center;
      position: relative;
      margin: 0px 20px 20px 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    .profile {
      &__container {
        position: relative;
        width: 100%;
        color: var(--light-grey);
        padding: 32px 16px;

        &--right {
          min-height: 160px;
          a {
            padding: 5px;
          }
          .anticon {
            color: #fff;
            font-size: 2rem;
            cursor: pointer;
          }
        }
        &--left {
          // min-height: 160px;
          margin-top: 20px;
          height: auto;
        }
      }
      &__data {
        position: relative;
        margin-left: 20px;
        color: #fff;
        font-size: 1rem;
        h2 {
          color: #fff;
        }
        &-function {
          a {
            color: #fff !important;
            text-decoration: underline;
          }
        }
        .tag-bg {
          margin-bottom: 10px;
          &.filled {
            background: #eff5ff;
            border-color: #5257ff;
          }
          a {
            text-decoration-line: underline;
            text-decoration-style: dotted;
            text-decoration-color: transparentize(#5257ff, 0.5);
            color: transparentize(#5257ff, 0.1);
            transition: all 150ms ease;
            &:hover {
              text-decoration-style: solid !important;
              text-decoration-color: #5257ff !important;
              color: #5257ff;
            }
          }
        }
      }
    }
  }
  &__body {
    // margin-top: -130px;
    padding: 0 !important;
    h2 {
      color: var(--secondary) !important;
    }
    &-card {
      margin-bottom: 20px !important;
      box-shadow: -5px 3px 3px 1px rgba(0, 0, 0, 0.1);
      &--custom {
        .ant-card-head-title {
          padding: 10px 0 0;
        }
      }
    }
    .detail-pane {
      background: #fff;
      // border-radius: 4px;
    }
  }
}
