.mx-1 {
  margin: 0 1rem;
}

.pointer {
  cursor: pointer;
}
.no-pointer {
  cursor: initial;
}

.align-vertically {
  display: flex;
  align-items: center;
}

.d-none {
  display: none;
}

.float-right {
  float: right;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.separator {
  border-bottom: 1px solid var(--separator-grey);
}

.center-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box-shadow {
  box-shadow: 20px 40px 80px 0 rgba(29, 29, 31, 0.08);
  &-small {
    // box-shadow: 3px 5px 20px 5px rgba(29, 29, 31, 0.08);
    box-shadow: 3px 5px 20px -5px rgba(29, 29, 31, 0.08);
  }
}

.soft-border {
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.no-padding {
  padding: 0px !important;
}

.activate-transitions {
  transition: all 200ms ease;
}
