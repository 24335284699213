.investments {
  &-total {
    .ant-statistic-title {
      color: #5257ff;
    }
  }
  &__tab {
    .ant-statistic-content-value {
      &-int {
        font-size: 1rem;
      }
      &-decimal {
        font-size: 0.8rem;
      }
    }
  }
}
