@import "./assets/styles/animations";
@import "./assets/styles/utils";
@import "./assets/styles/colors";
@import "./assets/styles/antd-overrides";
@import "./assets/fonts/stylesheet";
@import "./../node_modules/simplebar/dist/simplebar.min";
@import "./components/Grid/Grid";

html,
body {
  overflow-x: hidden;
  min-height: 100vh;
  text-rendering: geometricPrecision;
}

#root {
  height: 100%;
}

.dropdown-user {
  top: 110px !important;
}

h1,
h2,
h3 {
  font-family: "Lato", serif;
  //font-family: "Bodoni 72", serif;
}

h1 {
  &:before {
    content: " ";
    background: url("./assets/images/logo.png") no-repeat center;
    background-size: contain;
    padding: 0 15px 0 0;
    margin-right: 10px;
  }
}

.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.secondary {
  color: $secondaryColor !important;
}
.position-absolute {
  position: absolute !important;
}
.position-relative {
  position: relative !important;
}
.filter-color {
  color: $blueFilterColor !important;
  font-weight: bold;
}
.orange-title {
  color: $orangeColor !important;
  font-weight: bold;
}
.grey-light-color {
  color: $greyLightColor !important;
}
